body {
  background-color: #010115;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  height: 100%;
}
h1 {
  font-family: "Grandstander", sans-serif;
  font-size: 1.6em;
  font-weight: 100;
}
h1 strong {
  font-size: 1.8em;
}

.uploader {
  display: block;
}
input[type="file"] {
  display: none;
}
.custom-uploader {
  border: 2px solid #cccccc;
  font-family: "Grandstander", sans-serif;
  font-size: 1.2em;
  font-weight: 100;
  padding: 5px;
  margin: 5px;
}

.image-label-container {
  position: relative;
  width: 400px;
  height: 400px;
  padding: 15px;
}
img {
  max-width: 100%;
  max-height: 100%;
}
.low-confidence {
  position: absolute;
  font-family: "Grandstander", sans-serif;
  font-weight: 600;
  top: 25%;
  width: 100%;
  text-align: center;
  font-size: 1.5em;
}

.label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-30deg);
  font-size: 3em;
  font-family: "Luckiest Guy";
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
}
.thinking {
  font-size: 3em;
}
